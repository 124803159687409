var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-0 d-flex pb-2"},[_c('div',[_c('v-icon',{staticClass:"text-black",staticStyle:{"cursor":"pointer"},on:{"click":_vm.backToList}},[_vm._v("mdi-arrow-left")])],1),(_vm.route)?_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"font-weight-semibold text-black ml-5",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t('Update Captain'))+" ")])]):_vm._e(),(!_vm.route)?_c('div',{staticClass:"align-center"},[_c('p',{staticClass:"font-weight-semibold text-black ml-5",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t('Add New Captain'))+" ")])]):_vm._e()]),_c('v-divider',{staticClass:"mt-n2"}),_c('v-form',{ref:"form",staticClass:"multi-col-validation",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pa-3 mt-7"},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-semibold text-base text-green"},[_vm._v(" "+_vm._s(_vm.$t('User Details'))+" ")])])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"})],1),_c('v-card-text',[_c('v-row',{},[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" ")]),_c('v-text-field',{staticClass:"text-fields",attrs:{"variant":"underlined","placeholder":_vm.$t('Name'),"hide-details":"auto","disabled":_vm.view,"rules":[_vm.genericRules.required]},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Email ID'))+" ")]),_c('v-text-field',{staticClass:"text-fields",attrs:{"variant":"underlined","placeholder":_vm.$t('Email ID'),"hide-details":"auto","disabled":_vm.view,"rules":[_vm.genericRules.required, _vm.genericRules.email]},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Contact Number'))+" ")]),_c('vue-phone-number-input',{staticClass:"code-select",staticStyle:{"border":"none"},attrs:{"default-country-code":'SA',"color":'#00b2a9',"minLength":8,"maxlength":11,"disable-dropdown":true},on:{"input":_vm.handlePhone},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),(_vm.isPhoneLength)?_c('span',{staticStyle:{"color":"red","font-size":"11px"}},[_vm._v(_vm._s(_vm.$t('Please enter valid Mobile Number')))]):_vm._e()],1),(!_vm.ownerRole)?_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Boat Owner'))+" ")]),_c('v-select',{staticClass:"text-fields",attrs:{"items":_vm.boatOwners,"placeholder":_vm.$t('Select Boat Owner'),"variant":"underlined","item-text":"first_name","item-value":"id","rules":[_vm.genericRules.required],"disabled":_vm.view},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.user.owner_id),callback:function ($$v) {_vm.$set(_vm.user, "owner_id", $$v)},expression:"user.owner_id"}})],1):_vm._e(),(!_vm.route)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Password'))+" ")]),_c('v-text-field',{staticClass:"text-fields",attrs:{"variant":"underlined","placeholder":_vm.$t('Password'),"hide-details":"auto","rules":[_vm.genericRules.required]},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1):_vm._e(),(!_vm.route)?_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","sm":"12"}},[_c('p',{staticClass:"font-weight-semibold mb-0 text-black"},[_vm._v(" "+_vm._s(_vm.$t('Confirm Password'))+" ")]),_c('v-text-field',{staticClass:"text-fields",attrs:{"variant":"underlined","placeholder":_vm.$t('Confirm password'),"hide-details":"auto","rules":[_vm.genericRules.required]},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.user.confirm),callback:function ($$v) {_vm.$set(_vm.user, "confirm", $$v)},expression:"user.confirm"}})],1):_vm._e()],1),(!_vm.view)?_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"text-lower owner-info-btn border-card",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }